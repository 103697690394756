import React from "react";
import { Card, CardBody, Table } from "reactstrap";

const MessageTemplateTable = ({
	templates,
	setTemplate,
	deleteTemplate,
	toggle,
}) => {
	console.log(templates);

	const tableData = templates.map((template, index) => (
		<tr key={template.ID}>
			<th scope="row">{index + 1}</th>
			<td>{template.name}</td>
			<td>{template.body}</td>
			<td>
				{String(template.msgZone).trim() == "Account"
					? "BHPH"
					: template.msgZone}
			</td>
			<td className="d-flex justify-content-center align-items-center">
				<div
					className="btn-md btn btn-primary"
					onClick={() => {
						setTemplate(template);
						toggle();
					}}
				>
					Edit
				</div>
				<div
					className="btn btn-danger"
					onClick={() => deleteTemplate(template)}
				>
					X
				</div>
			</td>
		</tr>
	));

	return (
		<>
			{templates.length > 0 && (
				<Card>
					<CardBody>
						<div style={{ overflowX: "auto" }}>
							<Table striped hover>
								<thead>
									<tr>
										<th>#</th>
										<th>Name</th>
										<th>Body</th>
										<th>Zone</th>
										<th>View</th>
									</tr>
								</thead>
								<tbody>{tableData}</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			)}
		</>
	);
};

export default MessageTemplateTable;
