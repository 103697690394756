import carcomImage from "assets/img/Inventory-Advertising/carcom.png";
import carfaxImage from "assets/img/Inventory-Advertising/carfax.png";
import carguruImage from "assets/img/Inventory-Advertising/cargurus.png";
import autoTradeImage from "assets/img/Inventory-Advertising/autotrader.png";
import carsforsaleImage from "assets/img/Inventory-Advertising/carsforsale.png";
import cudlImage from "assets/img/Inventory-Advertising/cudl.png";
import dealerFrontImage from "assets/img/Inventory-Advertising/dealerFront.png";
import faceBookMKPLImage from "assets/img/Inventory-Advertising/faceBookMKPL.png";
import homenetImage from "assets/img/Inventory-Advertising/homenet.png";
import siriusXMImage from "assets/img/Inventory-Advertising/siriusXM.png";
import trueCarImage from "assets/img/Inventory-Advertising/truecars.png";
import v12Image from "assets/img/Inventory-Advertising/v12.png";
import webSiteImage from "assets/img/Inventory-Advertising/webSite.png";
import goldBumper from "assets/img/Inventory-Advertising/goldbumper.png";
import RVTrader from "assets/img/Inventory-Advertising/RVTrader.png";
import RVT from "assets/img/Inventory-Advertising/RVT.png";
import ICC from "assets/img/Inventory-Advertising/icc.png";
import carsAdvertizing from "assets/img/Inventory-Advertising/carsAdvertizing.png";
import vast from "assets/img/Inventory-Advertising/vast.png";
import craigslist from "assets/img/Inventory-Advertising/craigslist.png";
import offerUp from "assets/img/Inventory-Advertising/offerup.png";

export const InventoryAdvertisingData = [
	{
		ID: "1",
		Name: "Inventory Advertising",
		Image: carsAdvertizing,
		sendValue: 1,
		partnerSendVariable: "",
		sendVariable: "isAdvertised",
		vendorID: "",
		signUp: "",
		signIn: "",
	},
	{
		ID: "2",
		Name: "Website",
		Image: webSiteImage,
		sendValue: 1,
		partnerSendVariable: "sendWebsite",
		sendVariable: "sendWebsite",
		vendorID: "",
		signUp: "",
		signIn: "",
	},
	{
		ID: "3",
		Name: "AutoTrader",
		Image: autoTradeImage,
		sendValue: 0,
		partnerSendVariable: "sendAutoTrader",
		sendVariable: "sendAutoTrader",
		vendorID: "AutoTraderID",
		signUp: "https://www.autotrader.com/account/sign-up",
	},
	{
		ID: "4",
		Name: "Inventory Command Center",
		Image: ICC,
		sendValue: 0,
		partnerSendVariable: "sendICC_",
		sendVariable: "sendICC",
		vendorID: "",
		signUp: "",
		signIn: "",
	},

	{
		ID: "5",
		Name: "Cars.com",
		Image: carcomImage,
		sendValue: 0,
		partnerSendVariable: "sendCarsCOM",
		sendVariable: "sendCarsCOM",
		vendorID: "CarsCOMID",
		signUp: "carcom",
		signIn: "",
	},

	{
		ID: "6",
		Name: "CARFAX",
		Image: carfaxImage,
		sendValue: 0,
		partnerSendVariable: "sendCarfax",
		sendVariable: "carfax",
		vendorID: "CarfaxUser",
		signUp: "https://secure.billtrust.com/carfax/ig/signup",
		signIn: "",
	},
	{
		ID: "7",
		Name: "CarGurus",
		Image: carguruImage,
		sendValue: 0,
		partnerSendVariable: "sendCarGurus",
		sendVariable: "sendCarGurus",
		vendorID: "",
		signUp:
			"https://www.cargurus.com/Cars/authentication/renderRegisterLoginForm.action",
		signIn: "",
	},

	{
		ID: "8",
		Name: "Carsforsale",
		Image: carsforsaleImage,
		sendValue: 0,
		partnerSendVariable: "sendCarsForSale",
		sendVariable: "sendCarsForSale",
		vendorID: "CarsForSaleDealerID",
		signUp: "https://signup.carsforsale.com/",
		signIn: "",
	},

	{
		ID: "9",
		Name: "CUDL",
		Image: cudlImage,
		sendValue: 0,
		partnerSendVariable: "sendCudl",
		sendVariable: "sendCudl",
		vendorID: "CUDLID",
		signUp: "https://cudl.com/",
		signIn: "",
	},
	{
		ID: "10",
		Name: "HomeNet",
		Image: homenetImage,
		sendValue: 0,
		partnerSendVariable: "sendHomeNetPush",
		sendVariable: "sendHomeNetPush",
		vendorID: "",
		signUp: "https://www.homenetauto.com/dealers/",
		signIn: "",
	},
	{
		ID: "11",
		Name: "TrueCar",
		Image: trueCarImage,
		sendValue: 0,
		partnerSendVariable: "sendTrueCar",
		sendVariable: "sendTrueCar",
		vendorID: "",
		signUp: "https://www.truecar.com/",
		signIn: "",
	},
	{
		ID: "12",
		Name: "V12",
		Image: v12Image,
		sendValue: 0,
		partnerSendVariable: "sendV12",
		sendVariable: "sendV12",
		vendorID: "V12ADF_ID",
		signUp: "https://www1.v12software.com/",
		signIn: "",
	},
	{
		ID: "13",
		Name: "SiriusXM",
		Image: siriusXMImage,
		sendValue: 0,
		partnerSendVariable: "sendXMRadio",
		sendVariable: "sendXMRadio",
		vendorID: "",
		signUp: "siriusXM",
		signIn: "",
	},
	{
		ID: "14",
		Name: "DealerFront",
		Image: dealerFrontImage,
		sendValue: 1,
		partnerSendVariable: "sendDealerFront",
		sendVariable: "sendDealerFront",
		vendorID: "",
		signUp: "",
		signIn: "",
	},
	// {
	// 	ID: "15",
	// 	Name: "Facebook",
	// 	Image: faceBookMKPLImage,
	// 	sendValue: 1,
	// 	partnerSendVariable: "sendFacebook",
	// 	sendVariable: "sendFacebook",
	// 	vendorID: "",
	// 	signUp: "",
	// 	signIn: "autovizo",
	// },
	{
		ID: "16",
		Name: "Gold Bumper",
		Image: goldBumper,
		sendValue: 1,
		partnerSendVariable: "sendGoldBumper",
		sendVariable: "sendGoldBumper",
		vendorID: "",
		signUp: "",
		signIn: "",
	},
	{
		ID: "17",
		Name: "RVTrader",
		Image: RVTrader,
		sendValue: 1,
		partnerSendVariable: "sendRVTrader",
		sendVariable: "sendRVTrader",
		vendorID: "",
		signUp: "",
		signIn: "",
	},
	{
		ID: "18",
		Name: "RVT",
		Image: RVT,
		sendValue: 1,
		partnerSendVariable: "sendRVT",
		sendVariable: "sendRVT",
		vendorID: "",
		signUp: "",
		signIn: "",
	},
	{
		ID: "19",
		Name: "VAST",
		Image: vast,
		sendValue: 1,
		partnerSendVariable: "sendVast",
		sendVariable: "sendVast",
		vendorID: "",
		signUp: "https://www.vast.com/",
		signIn: "",
	},
	{
		ID: "20",
		Name: "Craigslist",
		Image: craigslist,
		sendValue: 0,
		partnerSendVariable: "",
		sendVariable: "",
		vendorID: "",
		signUp: "https://craigslist.org/",
		signIn: "",
	},
	{
		ID: "21",
		Name: "OfferUp",
		Image: offerUp,
		sendValue: 0,
		partnerSendVariable: "",
		sendVariable: "",
		vendorID: "",
		signUp: "https://offerup.com/",
		signIn: "",
	},
];
