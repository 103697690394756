import React, { useEffect, useState } from "react";
import { Button, Form } from "reactstrap";

import formNavigationUtils from "../../utils/formNavigationUtils";
import InputElement, {
	SelectorElement,
} from "../forms/components/InputElement";
import InputWrapper from "../forms/components/InputWrapper";
import { MessageTemplateVariablesAndInput } from "./MessageTemplateVariables";

import { object, setLocale, string } from "yup";
import { FormProvider, useForm } from "react-hook-form";

import { TemplateVariableProvider } from "../../context/TemplateVariableContext";
import InitializeTemplateVars from "../../utils/initializeTemplateVars";
import { yupResolver } from "@hookform/resolvers";

const MessageTemplateDetails = ({ template, setTemplate, saveTemplate }) => {
	const [msgZone, setMsgZone] = useState(template?.msgZone || "GENERAL");
	const [name, setName] = useState(template?.name || "");
	const [body, setBody] = useState(template?.body || "");
	const [subject, setSubject] = useState(template?.subject || "");

	// Updates the template object with local variables and calls save api
	const saveData = () => {
		const updatedTemplate = { ...template, name, body, subject, msgZone };
		saveTemplate(updatedTemplate);
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Define rules for each input field
	const schema = object().shape({
		messageTemplateName: string().required().max(50),
		//messageBody: string().required().max(1600),
		messageTemplateSubject: string().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setName(template?.name || "");
		setBody(template?.body || "");
		setSubject(template?.subject || "");
		setMsgZone(template?.msgZone || "GENERAL");
	}, [template]);

	// 👇 Wrap the inner form section in a dynamic key-based wrapper
	const RenderWithTemplateVars = () => (
		<TemplateVariableProvider key={msgZone}>
			<InitializeTemplateVars messageZone={msgZone} />
			<InputWrapper
				inputComponents={[
					<InputElement
						{...methods}
						colSize="col-lg-4"
						value={name}
						onChange={(e) => setName(e.target.value)}
						name="messageTemplateName"
						label="Template Name"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						name="method"
						label="Message Zone"
						value={msgZone}
						onChange={(e) => setMsgZone(e.target.value)}
						options={[
							{ value: "Deal", label: "Deal" },
							{ value: "Account", label: "BHPH" },
							{ value: "Customer", label: "Customer" },
							{ value: "Lead", label: "Lead" },
							{ value: "CreditApp", label: "CreditApp" },
							{ value: "Lender", label: "Lender" },
							{ value: "General", label: "General" },
						]}
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-6"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						name="messageTemplateSubject"
						label="Subject"
						type="text"
					/>,
					<MessageTemplateVariablesAndInput body={body} updateBody={setBody} />,
				]}
				buttons={
					<div className="d-flex justify-content-center">
						<Button color="primary" onClick={methods.handleSubmit(saveData)}>
							Save
						</Button>
						<Button color="danger" onClick={() => setTemplate({})}>
							Clear
						</Button>
					</div>
				}
			/>
		</TemplateVariableProvider>
	);

	return (
		<FormProvider {...methods}>
			<Form onKeyDown={formNavigationUtils}>
				<RenderWithTemplateVars />
			</Form>
		</FormProvider>
	);
};

export default MessageTemplateDetails;
